<template>
  <div v-if="!isFetching">
    <div style="padding-top:15px;display:inline-block;padding-left:10px;">
      <span>
        <a id="link_voltar" href="javascript:history.go(-1)">
          &lt; &lt; Voltar</a
        >
        <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
          Pensionista -
          <small>id: {{ id }}</small>
        </h5></span
      >
    </div>
    <div class="switch-div">
      <md-switch v-model="editionMode">Modo Edição </md-switch>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <div style="padding-top:15px;display:inline-block;">
          <h4 class="title">Tabela de Quotas</h4>
          <h5 class="title">
            Aqui é possivel criar novas Quota Partes e editar e excluí-las.
          </h5>
        </div>
        <div class="switch-div">
          <md-button
            v-show="showButton"
            class="md-raised md-success mt-4"
            @click="novaQuotaParte()"
          >
            Nova Quota
          </md-button>
        </div>
        <md-card>
          <md-card-content>
            <md-table
              :value="queriedData"
              :md-sort.sync="currentSort"
              :md-sort-order.sync="currentSortOrder"
              :md-sort-fn="customSort"
              class="paginated-table table-striped table-hover"
            >
              <md-table-toolbar>
                <md-field>
                  <label for="pages">Por pagina</label>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
                <span class="space-tool-bar"></span>

                <md-field>
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    placeholder="Mes/Ano"
                    v-model="searchQueryAnoMes"
                  >
                  </md-input>
                </md-field>
                <span class="space-tool-bar"></span>

                <md-field>
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    placeholder="Fator"
                    v-model="searchQueryFator"
                  >
                  </md-input>
                </md-field>

                <md-button
                  class="size35 md-just-icon md-simple"
                  @click.native="handleSearch()"
                >
                  <md-icon>search</md-icon>
                </md-button>
              </md-table-toolbar>
              <br />
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Id Quota" md-sort-by="idQuota">{{
                  item.idQuota
                }}</md-table-cell>

                <md-table-cell md-label="Mes/Ano" md-sort-by="anoMes">{{
                  item.anoMes
                }}</md-table-cell>

                <md-table-cell md-label="Fator" md-sort-by="fator">{{
                  item.fator
                }}</md-table-cell>

                <md-table-cell md-label="Ações" v-if="totalRegistros > 0">
                  <md-button
                    class="md-just-icon md-success md-simple"
                    @click.native="handleEdit(item)"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-tooltip md-direction="top">Editar</md-tooltip>
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger md-simple"
                    @click.native="handleDelete(item)"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-tooltip md-direction="top">Excluir</md-tooltip>
                    <md-icon>delete</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <div class="footer-table md-table">
              <table>
                <tfoot>
                  <tr>
                    <th
                      v-for="item in footerTable"
                      :key="item.idQuota"
                      class="md-table-head"
                    >
                      <div
                        class="md-table-head-container md-ripple md-disabled"
                      >
                        <div class="md-table-head-label">
                          {{ item }}
                        </div>
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">
                Mostrando {{ from + 1 }} até {{ to }} de {{ total }} registros
              </p>
            </div>
            <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </pagination>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <template>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ modalTitle }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="
                formCriar === true
                  ? handleSubmit(submit)
                  : handleSubmit(editRow)
              "
            >
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.anoMes"
                    :rules="{
                      required: true,
                      regex: /^(0\d{1}|1[0-2])\/(19|20)(\d{2})$/,
                      max: 7
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Mes / Ano</label>
                      <md-input
                        v-model="form.anoMes"
                        type="text"
                        :maxlength="7"
                        :key="componentKeyToRerender"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ "Mes/Ano deve ter formato MM/AAAA." }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <ValidationProvider
                    name="form.fator"
                    :rules="{
                      required: true,
                      regex: /^(?!00)\d+(\.\d)?\d*$/,
                      min: 1,
                      max: 8
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Fator %</label>
                      <md-input v-model="form.fator" type="text"></md-input>
                      <div>&nbsp;&nbsp;%</div></md-field
                    >
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{
                        "Fator deve ter formato decimal ou inteiro 99.99 ou 99 até 7 digitos."
                      }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="md-layout-item md-size-100 text-right">
                  <md-button type="submit" class="md-raised md-success mt-4">
                    {{ buttonModal }}
                  </md-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
        <template slot="footer"> </template>
      </modal>
    </template>
  </div>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import { Modal } from "@/components";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";

import { Pagination } from "@/components";
import Swal from "sweetalert2";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import { dataMesAno } from "@/pages/Dashboard/js/dataMesAno";

var pt_br = pt_BR;
var names = {};
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  props: {
    id: {
      type: String
    },
    idServidor: {
      type: String
    },
    idServPen: {
      type: String
    },
    isEditionIn: {
      type: Boolean
    }
  },
  components: {
    Pagination,
    Modal
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("papel", {
      papelSelected: "papelSelectedState"
    }),
    ...mapGetters("pensionista", {
      pensionistaSelected: "pensionistaState"
    }),
    user_profile_state: state => state.user_profile,

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      return result;
    },
    to() {
      if (this.totalRegistros === 0) {
        return 0;
      } else {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      }
    },
    from() {
      if (this.totalRegistros === 0) {
        return -1;
      } else {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    total() {
      return this.totalRegistros;
    },
    reload: function() {
      this.getPensionistaQuotaPartePagination();
      return this.tableData;
    }
  },
  data() {
    return {
      mensagem: "",
      idServPenSelected: this.idServPen,
      idServidorSelected: this.idServidor,
      idPensionista: this.id,
      classicModal: false,
      formCriar: true,
      buttonModal: "criar",
      modalTitle: "Nova Quota",
      idQuota: null,
      form: {
        servidorPensionista: "",
        anoMes: "",
        fator: ""
      },
      editionMode: false,
      showButton: false,
      isDisabledEdition: true,
      isFetching: true,
      isFetching2: true,
      isFetching3: true,
      papel: "",
      currentSort: "Mes/Ano",
      currentSortOrder: "desc",
      entidadeFilter: [],
      filtroEntidade: "",
      filtroSituacao: null,
      totalRegistros: 0,
      pagination: {
        perPage: 6,
        currentPage: 1,
        perPageOptions: [6, 12, 24, 48, 60, 72, 84, 96, 100, 200, 500, 1000],
        total: 0
      },
      footerTable: ["id Quota", "Mes/Ano", "Fator", "Ações"],
      searchQueryAnoMes: null,
      searchQueryFator: null,
      idEntidadeSelected: null,
      tableData: [],
      componentKeyToRerender: 0
    };
  },
  beforeMount() {
    this.getPapel();
    this.getPensionistaQuotaPartePagination();
    this.editionMode = this.isEditionIn;
  },
  methods: {
    ...mapActions("pensionista_quotaparte", [
      "ActionSetPENSIONISTA_QUOTAPARTE",
      "ActionPOSTPensionistaQuotaParte",
      "ActionDELETEPensionistaQuotaParte",
      "ActionPUTPensionistaQuotaParte",
      "ActionGetPensionistaQuotaPartePagination",
      "ActionGetPensionistaQuotaPartesByQuery"
    ]),
    emitToParent(value) {
      this.$emit("edition", value);
    },
    classicModalHide: function() {
      this.classicModal = false;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    callCpfMask(value) {
      return cpfMask(value.toString());
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    forceRerender() {
      this.componentKeyToRerender += 1;
    },
    novaQuotaParte() {
      this.classicModal = true;
      this.modalTitle = "Nova Quota";
      this.buttonModal = "criar";
      this.formCriar = true;
      this.form.fator = "";
      this.form.anoMes = "";
      this.form.servidorPensionista = this.idServPenSelected;
    },
    async getPensionistaQuotaPartePagination() {
      try {
        if (
          typeof this.pensionistaSelected !== "undefined" &&
          this.pensionistaSelected !== "" &&
          this.pensionistaSelected !== null &&
          this.pensionistaSelected.idPensionista
        ) {
          let papel = this.papel;
          let id = this.idServPenSelected;
          let noOfRecords = this.pagination.perPage;
          let pageIndex = this.pagination.currentPage;
          let response = await this.ActionGetPensionistaQuotaPartePagination({
            id,
            papel,
            noOfRecords,
            pageIndex
          }).then(res => {
            if (res.body.mensagem) {
              if (res.body.mensagem.includes("não")) {
                this.totalRegistros = 0;
                this.tableData = [
                  {
                    idQuota: "",
                    anoMes: "",
                    fator: ""
                  }
                ];
                this.isFetching2 = false;
                this.isFetching3 = false;
                this.isFetching = false;
              }
            } else {
              this.tableData = res.body.pensionistaQuotaParte;
              this.totalRegistros = res.body.totalRegistros;
              this.isFetching2 = false;
              this.isFetching3 = false;
              this.isFetching = false;
            }
          });
        } else {
          window._Vue.$router
            .push({ name: `Cadastro Pensionista` })
            .catch(err => {});
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getPensionistaPaginationNext() {
      try {
        let papel = this.papel;
        let id = this.idServPenSelected;
        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        let response = await this.ActionGetPensionistaQuotaPartePagination({
          id,
          papel,
          noOfRecords,
          pageIndex
        }).then(res => {
          if (res.body.mensagem) {
            if (res.body.mensagem.includes("não")) {
              this.totalRegistros = 0;
              this.tableData = [
                {
                  idQuota: "",
                  anoMes: "",
                  fator: ""
                }
              ];
            }
          } else {
            this.tableData = res.body.pensionistaQuotaParte;
            this.totalRegistros = res.body.totalRegistros;
          }
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    goToPensionistaViewer(item) {
      this.ActionSetPENSIONISTA(item);
      let id = item.idPensionista;
      //window._Vue.$router
      //  .push({ path: `/pensionistas/detalhe_pensionista/${id}` })
      //  .catch(err => {});
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          if (
            typeof a[sortBy] !== "undefined" &&
            typeof b[sortBy] !== "undefined"
          ) {
            return a[sortBy].toString().localeCompare(b[sortBy].toString());
          }
        }
        if (
          typeof a[sortBy] !== "undefined" &&
          typeof b[sortBy] !== "undefined"
        ) {
          return b[sortBy].toString().localeCompare(a[sortBy].toString());
        }
      });
    },
    async handleSearch() {
      if (
        (this.searchQueryAnoMes === "" || this.searchQueryAnoMes === null) &&
        (this.searchQueryFator === "" || this.searchQueryFator === null)
      ) {
        this.getPensionistaQuotaPartePagination();
      } else {
        let papel = this.papel;
        let anoMes = null;
        let fator = null;
        let id = this.idServPenSelected;

        if (this.searchQueryAnoMes !== "" && this.searchQueryAnoMes !== null) {
          anoMes = this.searchQueryAnoMes;
        }
        if (this.searchQueryFator !== "" && this.searchQueryFator !== null) {
          fator = this.searchQueryFator;
        }

        let noOfRecords = this.pagination.perPage;
        let pageIndex = this.pagination.currentPage;
        await this.ActionGetPensionistaQuotaPartesByQuery({
          papel,
          id,
          anoMes,
          fator,
          noOfRecords,
          pageIndex
        }).then(response => {
          if (response.body.mensagem) {
            if (response.body.mensagem.includes("não encontrados")) {
              this.totalRegistros = 0;
              this.tableData = [
                {
                  idQuota: "",
                  anoMes: "",
                  fator: ""
                }
              ];
            }
          } else {
            this.tableData = response.body.pensionistaQuotaParte;
            this.totalRegistros = response.body.totalRegistros;
          }
        });
      }
    },
    async submit() {
      try {
        let papel = this.papel;
        let form = this.form;
        let response = await this.ActionPOSTPensionistaQuotaParte({
          papel,
          form
        });
        if (response.status === 200) {
          this.mensagem = response.body.mensagem;
          Swal.fire({
            //title: "Sucesso",
            icon: "success",
            text: "Quota Criada",
            customClass: {
              confirmButton: "md-button md-success btn-fill"
            },
            buttonsStyling: false
          });
        }
        this.classicModalHide();
        this.reload;
      } catch (err) {
        this.classicModalHide();
        console.log(err);
        this.handleError(err);
      }
    },
    handleDelete(item) {
      Swal.fire({
        title: "Você tem certeza ?",
        text: `Se você excluir a Quota será excluida da Pensão ! Isto será irreversível !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir isto!",
        cancelButtonText: "CANCELAR",
        customClass: {
          confirmButton: "md-button md-success md-raised mt-4",
          cancelButton: "md-button md-danger md-raised mt-4"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    async deleteRow(item) {
      try {
        let id = item.idQuota;
        let papel = this.papel;
        let response = await this.ActionDELETEPensionistaQuotaParte({
          id,
          papel
        });
        if (response.status === 200) {
          let indexToDelete = this.tableData.findIndex(
            tableRow => tableRow.idQuota === item.idQuota
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
          this.mensagem = response.body.mensagem;
          if (this.mensagem.includes("não")) {
            Swal.fire({
              title: `Quota: ${item.idQuota} Não Excluida ! - Pensionista: ${idPensionista} `,
              icon: "error",
              text: this.mensagem,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised md-danger btn-fill"
              },
              buttonsStyling: false
            });
          } else {
            Swal.fire({
              icon: "success",
              title: `Quota: Excluida!`,
              text: `${this.mensagem}` + "\nid:" + `${item.idQuota} `,
              customClass: {
                confirmButton: "md-button md-raised mt-4 md-success btn-fill"
              },
              buttonsStyling: false
            });
          }
          this.reload;
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    checkEditionDisabled() {
      if (this.isDisabledEdition === true) {
        return "disabled";
      } else {
        return null;
      }
    },
    handleEdit(item) {
      this.formCriar = false;
      this.buttonModal = "Atualizar";
      this.modalTitle = "Editar Quota";
      this.idQuota = item.idQuota;
      this.form.fator = item.fator;
      this.form.anoMes = item.anoMes;
      this.form.servidorPensionista = this.idServPenSelected;
      this.classicModal = true;
    },
    async editRow() {
      try {
        let id = this.idQuota;
        let form = {
          servidorPensionista: this.form.servidorPensionista,
          idQuota: this.idQuota,
          fator: this.form.fator,
          anoMes: this.form.anoMes
        };
        let papel = this.papel;
        let response = await this.ActionPUTPensionistaQuotaParte({
          id,
          papel,
          form
        });
        if (response.status === 200) {
          if (response.body.mensagem.includes("atualizado com sucesso")) {
            this.classicModalHide();
            this.reload;
            this.idQuota = "";
            Swal.fire({
              icon: "success",
              //title: "Atualizado!",
              text: `${response.body.mensagem}`,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false
            });
          } else {
            this.classicModalHide();
            this.reload;
            this.idQuota = "";
            Swal.fire({
              icon: "warning",
              //title: "Atualizado!",
              text: `${response.body.mensagem}`,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false
            });
          }
        }
      } catch (err) {
        this.classicModalHide();
        console.log(err);
        this.handleError(err);
      }
    },
    handleError(err) {
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  },
  watch: {
    "form.anoMes": function(val, oldVal) {
      this.form.anoMes =
        val === null || val === "" ? null : dataMesAno(val.toString());
    },
    "form.fator": function(val, oldVal) {
      this.form.fator =
        val === null || val === "" ? null : val.toString().replace(",", ".");
    },
    pagination: {
      handler: function(val, oldVal) {
        if (
          (this.idEntidadeSelected === null ||
            this.idEntidadeSelected === "") &&
          this.filtroEntidade === "" &&
          (this.searchQuerySiapePensionista === "" ||
            this.searchQuerySiapePensionista === null) &&
          (this.searchQueryCPF === "" || this.searchQueryCPF === null) &&
          (this.searchQueryNomeCompleto === "" ||
            this.searchQueryNomeCompleto === null) &&
          (this.searchQueryUpagDescricao === "" ||
            this.searchQueryUpagDescricao === null)
        ) {
          this.getPensionistaPaginationNext();
        } else {
          this.handleSearch();
        }
      },
      deep: true
    },
    editionMode: function(val, oldVal) {
      let edition = val;
      if (edition) {
        this.isDisabledEdition = false;
        this.showButton = true;
        this.showButtonAtualizar = true;
        ///checkEditionDisabled();
      } else {
        this.isDisabledEdition = true;
        this.showButton = false;
        this.showButtonAtualizar = false;
      }
      this.emitToParent(edition);
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: 400;
}

.footer-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: bold;
}
.md-table-cell {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.74rem;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}

.size35 i {
  font-size: 35px !important;
}

::v-deep .md-toolbar-section-start {
  padding-bottom: 20px;
}

::v-deep .md-card .md-card-content {
  padding: 2px 20px;
}

::v-deep .md-switch-label {
  font-weight: bold;
  color: rgba(14, 14, 14, 0.52);
}

::v-deep .md-switch.md-checked .md-switch-container {
  background-color: #49a54d !important;
}

::v-deep .md-switch.md-checked .md-switch-thumb {
  border: 1px solid #49a54d;
}

.switch-div {
  float: right;
}

#link_voltar {
  color: green;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}

a:hover,
a:focus {
  color: green !important;
  text-decoration: none !important;
}

::v-deep .modal-container {
  max-width: 400px;
}
</style>
